#login_wrapper .login_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: white;
    z-index: 1000;
    border: 1px solid var(--grey-30);
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 95vh;
    overflow-y: auto;
}
.input-container {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    max-width: 23rem !important; 
    margin: 0 auto !important;
}

.password-icon {
    position: absolute !important;
    right: 31px !important; 
    top: 39% !important; 
    cursor: pointer !important;
    color: var(--grey-90) !important;
    z-index: 2 !important;
    background-color: white !important;
}
/* Responsive styling for mobile screens */

@media screen and (max-width: 768px) {
    .password-icon {
        right: 14% !important;
    }
}
@media screen and (max-width: 426px) {
    .password-icon {
        right: 9% !important;
    }
}
@media screen and (max-width: 320px) {
    .password-icon {
        right: 12% !important;
    }
}
@media screen and (min-width: 291px) and (max-width: 319px){
    .password-icon {
        right: 8% !important;
    }
}   

@media (max-width: 1024px) and (min-width: 720px) {
    #login_wrapper .login_content {
        width: 85%;
        /* Smaller width for tablets */
        max-width: 47rem;
        /* Reduce maximum width further */
        height: 40rem;
        /* Adjust height dynamically */
        padding: 15px;
        /* Reduce padding for a more compact layout */
        overflow-y: auto;
        /* Enable vertical scrolling */
        scroll-behavior: smooth;
    }

    .not-from-belgium {
        margin-left: 24rem !important;

    }

    .cities-label-container {
        margin-top: 2rem;
    }

    .not-from-belgium {
        margin-left: 24rem;
    }

    #login_wrapper .login_content figure {
        margin: 0 0 !important;
    }

    #login_wrapper .login_extra_info {
        margin: 12px 0 !important;
    }

    .following-municipality-select-wrapper {
        margin: 0rem !important;
    }

    .not-from-belgium {
        margin-top: 1.5rem;
    }
}

@media (max-width: 720px) {
    #login_wrapper .login_content {
        width: 90%;
        /* Smaller width for mobile devices */
        max-width: 300px;
        /* Even more compact on smaller devices */
        height: auto;
        /* Ensure content fits dynamically */
        padding: 10px;
        /* Minimal padding for small screens */
    }

    .slider-container img {
        width: 2rem;
        height: 2rem;
    }
}

@media (max-width: 450px) {

    #login_wrapper .login_content {
        height: 48rem;
    }

    #login_wrapper .login_content figure {
        margin: 0 0 !important;
    }

    #login_wrapper .login_content h2 {
        font-size: 14px;
        padding: 0.4rem;
    }

    #login_wrapper .login_content .innerLogin .login .email,
    #login_wrapper .login_content .innerLogin .login .password {
        padding: none;
        width: 19rem !important;
        margin: 0 auto !important;
    }

    /* #passwordIcon {
        margin-top: 95px !important;
        margin-left: 19rem !important;
    } */

    #login_wrapper .login_content {
        max-width: 30rem !important;
    }

    #login_wrapper .login_content .login_button {
        margin-bottom: 0rem !important;
    }

    .slider-container {
        width: 17rem !important;
        margin-top: 5px !important;
    }

    #passwordIcon_reg1 {
        margin-top: 68px !important;
        margin-left: 19rem !important;
    }

    #passwordIcon_reg2 {
        margin-top: 123px !important;
        margin-left: 19rem !important;
    }

    #login_wrapper .login_content h2 {
        margin-bottom: 0rem !important;
        font-size: 20px;
    }

    #login_wrapper .login_extra_info {
        margin: 0rem !important;
    }

    #login_wrapper .login_extra_info_fields {
        margin-left: 0rem !important;
        grid-template-columns: repeat(1, 1fr) !important;
        margin: 0rem auto !important;
        margin-top: 1rem !important;

    }

    .not-from-belgium {
        margin-left: 8rem !important;
        margin-bottom: 3rem !important;
        margin-top: 1rem !important;
    }

    .multi_select_help_text {
        font-size: 9px !important;
    }

    .MyCity .searchbar_wrapper {
        margin: 0 auto;
    }


    #login_wrapper .login_content .forgot_password {
        margin-bottom: 15px !important;

    }

    .MyCity .MyCity_text p,
    .MyCity .MyCity_text .p {
        text-align: center !important;
        margin-bottom: 2rem !important;
    }

    .MyCity .MyCity_text .buttonWrapper.S {
        margin: 0 auto !important;
        width: 73% !important;
    }

    .MyCity .MyCity_text .button_wrapper {
        gap: 2rem !important;
    }

    .MyCity #procentLogo {
        padding: 21px !important;
    }

    nav .navLogo img.municipality {
        left: 271% !important;
        padding: 13px !important;
    }

    nav .navLogo {
        width: 33px !important;
    }

    #login_wrapper .login_content .close_icon {
        z-index: 10 !important;
    }

    #login_wrapper .login_content .login_register {
        gap: 10px;
    }
}

@media (max-width: 430px) {

    #login_wrapper .login_content .innerLogin .login .email,
    #login_wrapper .login_content .innerLogin .login .password {
        width: 99% !important;
    }

    #login_wrapper .login_content .login_register {
        /* For Mobile 320px */
        width: 100%;
        height: 90%;
        padding: 0;
        margin: 0;
        gap: 10px;
    }

    #login_wrapper .login_content .login_form .login_register {
        /* For Mobile 320px */
        width: 90%;
        height: 90%;
        padding: 0;
        margin: 0;
    }

    #login_register {
        display: flex;
        flex-direction: column;
        align-items: flex-end !important
    }

    #form__field {
        width: 110%;
        height: 30px;
    }
}

#login_wrapper .login_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1693D4;
    opacity: 0.2;
    z-index: 999;
}

#login_wrapper .login_content .close_icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--grey-100);
    padding: 5px;
    cursor: pointer;
    transition: 0.2s;
}

#login_wrapper .login_content .close_icon:hover {
    padding: 3px;
}

#login_wrapper .login_content .close_icon.zoom {
    z-index: 99;
    color: white;
}

#login_wrapper .login_content figure {
    height: 100px;
    width: auto;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 25px 0;
}

#login_wrapper .login_content figure img {
    max-height: 100%;
    max-width: 82%;
}

#login_wrapper .login_content h2 {
    display: flex;
    text-transform: uppercase;
    color: var(--grey-70);
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1rem;
}
@media (max-width: 425px) {
    #login_wrapper .login_content h2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        left: auto;
    }
}

@media (max-width: 376px) {
    #login_wrapper .login_content h2 {
        font-size: 18px;
        align-items: center;
        left: 5px;
    }
}

@media (max-width: 320px) {
    #login_wrapper .login_content h2 {
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        left: 1rem;
        font-size: 16px;
    }
}


#login_wrapper .login_content .innerLogin {
    display: flex;
    justify-content: center;
    /* height: calc(100% - 267px); */
}

#login_wrapper .login_content .innerLogin .login {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#login_wrapper .login_content .innerLogin .login .email,
#login_wrapper .login_content .innerLogin .login .password {
    padding: 0.5rem;
    box-sizing: border-box;
    width: 23rem !important;
    margin: 0 auto;
    height: 3rem;
}

#login_wrapper .login_content .innerLogin .login_register .email,
#login_wrapper .login_content .innerLogin .login_register .password {
    padding: 0.5rem;
    box-sizing: border-box;
    width: 23rem !important;
    margin: 0 auto;
    height: 4rem;
}
@media (max-width: 320px) {
    #login_wrapper .login_content .innerLogin .login_register .email,
    #login_wrapper .login_content .innerLogin .login_register .password {
        width: 17rem !important;
    }
}
@media (max-width: 320px) {
    #login_wrapper .login_content .innerLogin .login .email,
    #login_wrapper .login_content .innerLogin .login .password {
        width: 17rem !important;
    }
}

#login_wrapper .login_content .innerLogin .login {
    padding: 0;
    /* Remove unnecessary padding */
}

.input-container {
    display: flex !important;
    align-items: flex-end !important;
    justify-content: center !important;
    position: relative;

    /* width: 100% !important;
    max-width: 100% !important;
    overflow: hidden !important;
    gap: 10px !important; Adjust space between input and icon */
}

.password {
    flex: 1 !important;
    min-width: 4 !important;
    /* Ensures it shrinks properly */


}

#passwordIcon {
    cursor: pointer !important;
    display: flex !important;
    right: 9% !important;
    top: 23px !important;
    position: absolute !important;
}

/* Increase right by 10px on mobile devices (screen width <= 768px) */
@media screen and (max-width: 768px) {

    #passwordIcon {
        right: 12% !important;
    }
}
@media screen and (max-width: 375px) {

    #passwordIcon {
        right: 10% !important;
    }
}
@media screen and (max-width: 1024px) {

    #passwordIcon {
        right: 15% !important;
    }
}
@media screen and (max-width: 426px) {

    #passwordIcon {
        right: 10% !important;
    }
}
@media screen and (min-width: 291px) and (max-width: 319px){
    #passwordIcon {
        right: 8% !important;
    }
}

#login_wrapper .login_content .cread {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

#login_wrapper .login_content .cread p,
#login_wrapper .login_content .cread ul {
    padding: 7px 25px;
    font-size: 13px;
}

#login_wrapper .login_content .cread ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

#login_wrapper .login_content .cread figure {
    margin: 0;
    text-align: center;
    height: max-content;
    transition: .2s;
    width: 33%;
}

#login_wrapper .login_content .cread figure img {
    border: 1px solid var(--grey-20);
    cursor: pointer;
}

#login_wrapper .login_content .cread figure.zoom {
    position: unset;
}

#login_wrapper .login_content .cread figure.zoom img {
    position: absolute;
    border: 0;
    width: 100%;
    max-width: unset;
    max-height: unset;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    transition: .2s;
    z-index: 1;
}

#login_wrapper .login_content .cread figure.zoom::after {
    content: '';
    width: 100%;
    position: absolute;
    display: block;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 0;
}

#login_wrapper .login_content .cread figure .icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: .2s;
}

#login_wrapper .login_content .cread figure:hover .icon {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);

}

#login_wrapper .login_content .cread figure:hover img {
    opacity: .8;
}

#login_wrapper .login_content .forgot_password {
    color: #1693D4;
    font-size: 11px;
    max-width: 230px;
    text-align: right;
    width: 100%;
    cursor: pointer;
    margin-left: 14%;
    margin-bottom: 1rem;
margin-top: 3%;
}

#login_wrapper .login_content .forgot_password:hover {

    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

#login_wrapper .login_content .error_message {
    color: var(--error);
    font-size: 13px;
    margin-top: 5px;
}

#login_wrapper .login_content .login_button {
    width: 100%;
    background-color: #1693d4;
    color: white;
    border-radius: 5px;
    max-width: 300px;
    padding: 10px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
    margin-bottom: 1rem;
}

#login_wrapper .login_content .login_button_register {
    width: 100%;
    background-color: #1693d4;
    color: white;
    border-radius: 5px;
    max-width: 300px;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
    margin-top: 1rem;
}

#login_wrapper .login_content .login_button:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

#login_wrapper .already_account {
    color: #1693D4;
    font-size: 13px;
    max-width: 300px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

#login_wrapper .already_account:hover {
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

#login_wrapper .login_type_buttons {
    display: flex;
}

#login_wrapper .type_button {
    border: 1px solid #1693D4;
    background-color: white;
    color: #1693D4;
    border-radius: 5px;
    padding: 7px 5px;
    margin: 0 5px;
    font-size: 13px;
    transition: .5s;
    cursor: pointer;
}

#login_wrapper .type_button.active,
.type_button:hover {
    border: 1px solid #1693D4;
    background-color: #1693D4;
    color: var(--white);
}



#login_wrapper .form__label::after {
    height: 3px !important;
}

#login_wrapper .login_extra_info {
    margin: 38px 0;
}

#login_wrapper .login_extra_info_fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 26px;
    justify-items: center;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
}

.login_register_button {
    display: flex;
    justify-content: center;
    /* Horizontally centers the button */
    align-items: center;
    /* Vertically centers the button */
    width: 20vw;
    /* Adjusts the button width to be 20% of the viewport width */
    margin: 0 auto;
    margin-top: 1rem;
}

#login_wrapper .inputFieldWrapper.half,
.selectField,
.inputField.calendarDate {
    width: 40%;
    margin: 10px;
}

#login_wrapper .inputFieldWrapper.half .form__group {
    margin: 0;
}

.language-container {
    position: relative;
    /* Ensures horizontal centering */
    display: flex;
    flex-direction: column;
    /* Keeps items stacked vertically */
    justify-content: center;
    /* Centers items vertically */
    align-items: center;
    /* Centers items horizontally */
    z-index: 1;
    /* Add a higher z-index for the container */
    margin-left: -4px;

}

.pref_language {
    background: #fff;
    font-size: 9px;
    position: absolute;
    top: -4px;
    /* Adjust based on label placement above the field */
    left: 16px;
    /* Align the label horizontally with the input field */
    padding: 0 4px;
    /* Add padding to blend it better with the background */
    z-index: 1;
    color: #333;
    /* Optional: Change the label text color */
}



.login_extra_info_fields .login_button {
    max-width: 83% !important;
}

#UsernamePassword {
    margin-top: 20px
}

@media only screen and (max-width: 550px) {
    .cread-container {
        flex-direction: column;
        align-items: center;

    }

    .cread-container ul {
        order: -1;
    }

    #login_wrapper .login_content {
        overflow-y: auto;
        scroll-behavior: smooth;
    }
}

.slider-container {
    width: 35rem;
    margin: 0 auto;
    margin-bottom: 2rem;
}
@media (max-width: 320px) {
    .slider-container {
        width: 17rem !important;
        
    }
}


.slider-container img {
    width: 100%;
    /* Ensure images are responsive */
    height: auto;
    /* Maintain aspect ratio */
    display: block;
    /* Prevent inline element spacing issues */
}

.password {
    margin-bottom: 2rem;

}

.login_register {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* @media (max-width: 1024px) {

    #login_wrapper .login_content .innerLogin .login_register .email,
    #login_wrapper .login_content .innerLogin .login_register .password {
        width: 80%;
        max-width: 400px;
        box-sizing: border-box;
        height: 4rem;
        margin-left: 8rem;
        padding: 0.5rem !important;
    }
} */

/* For Municipalities selection in registration modal */
/* General container styles */
.location-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-width: 400px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Municipality section */
.municipality-container {
    position: relative;
    margin: 0 auto;
    /* Ensures horizontal centering */
    display: flex;
    flex-direction: column;
    /* Keeps items stacked vertically */
    justify-content: center;
    /* Centers items vertically */
    align-items: center;
    /* Centers items horizontally */
    z-index: 1;
    /* Add a higher z-index for the container */

}

.municipality-label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.municipality-select-wrapper {
    width: 16rem;
    /* Set the fixed width for the container */
    gap: 8px;
    font-size: 11.1px;
    color: #333;
    justify-content: center;
    margin-left: 4rem;
    margin-top: -33px;

}

.municipality-select {
    width: 100%;
    /* Ensure the select takes the full width of the container */
}

.country-container {
    display: flex;
    color: #333;
    margin: 1rem;
    width: 16rem;
    gap: 8px;
    font-size: 15px;
    color: #333;
    justify-content: center;
    margin-left: 4rem;
    margin-top: -3rem;
}



/* Not from Belgium section */
.not-from-belgium {
    display: flex;
    gap: 8px;
    font-size: 15px;
    color: #333;
    margin-left: 23rem;
    margin-top: 2rem;
}

.not-from-belgium input {
    margin: 0;
    cursor: pointer;
    width: 16px;
}

.not-from-belgium label {
    cursor: pointer;
}

/* Country section */
.country-container {
    display: flex;
    flex-direction: column;

}

.country-label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}



/* Scrollbar customization (optional) */
.municipality-select::-webkit-scrollbar {
    width: 8px;
}

.municipality-select::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.municipality-select::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

/* Responsive design */
@media (max-width: 480px) {
    .location-container {
        padding: 12px;
        max-width: 100%;
    }

    .municipality-select {
        font-size: 13px;
    }

    .country-input {
        font-size: 13px;
    }
}




.following-municipality-select-wrapper {
    margin: 2rem;
    margin-bottom: 5px;
}


/* #passwordIcon {
    position: absolute;
    /* width: max-content; */
/* border-radius: 5px; 
    color: var(--grey-90);
    cursor: pointer;
    margin-top: 84px;
    margin-left: 17rem;
    height: 1rem; 
} */

#passwordIcon_reg1 {
    position: absolute;
    /* width: max-content; */
    border-radius: 5px;
    color: var(--grey-90);
    cursor: pointer;
    margin-top: 77px;
    margin-left: 17rem;
    z-index: 1;
}

#passwordIcon_reg2 {
    position: absolute;
    /* width: max-content; */
    border-radius: 5px;
    color: var(--grey-90);
    cursor: pointer;
    margin-top: 133px;
    margin-left: 17rem;
    z-index: 1;

}

#passwordIcon:hover {
    background-color: var(--main_soft);
}

.dual-listbox-filter {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.multi_select_help_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}

.cities-label-container {
    margin-bottom: -29px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.sidebarText{
  display: flex;
  justify-content: start;
  align-items: start;
}





@media  (max-width: 292px) {
    #login_wrapper .login_content .forgot_password {
       margin-left: 5%;
       margin-top: 7%;
    }
    #login_wrapper .login_content .login_register{
        gap: 0px;
    }
    .slider-container{
        width  :15rem !important;
    }
    #login_wrapper .already_account
    {
        font-size: 11px;
    }
}