.dual-list-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  max-height: 250px; /* Ensure static height */
  min-height: 250px; /* Prevent shrink */
  text-align: center;
  width: 46%; /* Ensure width remains static */
}

.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-height: 30px; /* Static height */
}

.scrollable-list {
  flex: 1;
  overflow-y: scroll;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  overflow-x: scroll;
  white-space: nowrap;
  min-height: 200px; /* Ensures height does not shrink */
  max-height: 200px;
  display: flex;
  flex-direction: column;
  min-width: 220px;
  max-width: 220px;
}

.empty-message {
  padding: 10px;
  color: #888;
  text-align: center;
  font-size: 14px;
  height: 100%;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 230px;
  max-width: 220px;
  overflow-x: auto;

}

.list-item {
  padding: 2px;
  align-items: start;
  font-size: 14px;
  display: flex;
  color: black;
  white-space: nowrap; 
  flex-shrink: 0;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

}

@media (max-width: 768px) {
  .button-group {
    flex-direction: row;
    margin-top: 0%;
  }
}

button {
  padding: 8px;
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button:disabled {
  background-color: #d2d2d2;
  cursor: not-allowed;
}

.list-item.selected {
  background-color: var(--cn);
  color: white;
}

.fa-angles-up {
  transform: rotate(270deg) !important;
}

.fa-angles-down {
  transform: rotate(270deg) !important;
}

@media (max-width: 768px) {
  .fa-chevron-right {
    transform: rotate(90deg) !important;
  }

  .fa-chevron-left {
    transform: rotate(90deg) !important;
  }

  .fa-angles-up {
    transform: rotate(360deg) !important;
  }

  .fa-angles-down {
    transform: rotate(360deg) !important;
  }
}

@media (max-width: 768px) {
  .dual-list-container {
    flex-direction: column;
    align-items: center; /* Center align everything */
    text-align: center;
  }

  .list {
    width: 100%; /* Make the lists take most of the screen width */
    max-width: 300px; /* Prevent them from getting too wide */
    min-height: 250px;
  }

  .scrollable-list {
    min-width: 100%; /* Ensures it takes full width of its container */
    max-width: 100%;
  }

  .button-group {
    flex-direction: row; /* Arrange buttons horizontally */
    justify-content: center; /* Center align buttons */
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
}
