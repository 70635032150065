.follwongConflict {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1394d25e;
}
.follwongConflict .container  {
    background-color: white;
    border: 1px solid var(--grey-10);
    border-radius: 5px;
    padding: 10px 25px;
    z-index: 100;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}
.follwongConflict .container .icon {
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 5px;
    cursor: pointer;
    transition: .2s;
}
.follwongConflict .container .icon:hover {
    transform: scale(1.1);
}
.follwongConflict .container b {
    color: var(--pn);
}
.follwongConflict .container  figure {
    max-width: 385px;
    margin: 0 auto;
}
.follwongConflict .container  figure img {
    width: 100%;
}
.follwongConflict .container  .buttons {
    display: flex;
    justify-content: center;

}