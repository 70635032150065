@font-face { font-display: swap; font-family: openSans; font-weight: unset; src: url('../fonts/openSans/OpenSans-Regular-webfont.woff') }
@font-face { font-display: swap; font-family: openSansLight; font-weight: unset; src: url('../fonts/openSans/OpenSans-Light-webfont.woff') }
@font-face { font-display: swap; font-family: openSansBold; font-weight: unset; src: url('../fonts/openSans/OpenSans-Bold-webfont.woff') }
@font-face { font-display: swap; font-family: openSansItalic; font-weight: unset; src: url('../fonts/openSans/OpenSans-Italic-webfont.woff') }

/* Reset all the styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;

}
/* Removes the black border from chrome */
*,*:focus,*:hover{
    outline:none;
}

/* Set the default font */
body, html, #root, .App {
    font-family: 'openSans', sans-serif;
    font-size: 14px;
    height: 100%;
    width: 100%;
}


.mapDrawerTop { min-height: 130px;}

#detailPage { 
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#detailContent {
    /* height: calc(100% - 90px); */
    /* margin-top: -50px; */
    display: flex;
    width: 100%;
}
#detailContent.empty {
    height: calc(100% - 90px);
}

#detailContent .hamburgerIcon {
    display: none;
    position: absolute;
    top: 50px;
    left: 20px;
    cursor: pointer;
}

.icon.closeIcon {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  z-index:0;
}

#detailContent .detailSidebar {
    background: var(--grey);
    display: flex;
    flex-direction: column;
    position: -webkit-fixed;
    position: fixed;
    top: 0;
    min-width: 280px;
    width: 280px;
    border-right: 1px solid var(--grey-10);
    height: calc(100% - 40px);
    margin-top: 40px;
  z-index:0;

}
.detailSidebar .goBack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--cn);
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index:0;
    transition: .25s;

}
.detailSidebar .goBack:hover .icon { transform: translateX(-5px);}


.detailSidebar .goBack .icon {
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 5px;
    transition: .25s;

}
#detailContent .detailSidebar .logo {
    width: 280px;
    height: 165px;
    position: relative;
    /* border-bottom: 1px solid var(--grey-10); */
    display: flex;
    align-items: center;
    justify-content: center;

}
#detailContent .detailSidebar .logo img {
    max-height: 100%;
    max-width: 100%;
    padding: 10px;
    position: absolute;
}

#detailContent .detailSidebar .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    height: 80px;
    border-bottom: 1px solid var(--grey-20);
    /* margin: 10px 0; */
}
#detailContent .detailSidebar .info h3 { margin: 10px 0; text-transform: uppercase; font-size: 14px; color: var(--grey-100);}
#detailContent .detailSidebar .info .buttonWrapper.S{ width: 50%; margin: 0; height: 25px;}
#detailContent .detailSidebar .info .buttonWrapper.S .button { height: 25px;}
#detailContent .detailSidebar ul li { 
    list-style-type: none;
    height: 46px;
    overflow: hidden;
    transition: .2s;
}



#detailContent .detailSidebar ul li.active { 
    height: -webkit-max-content;
    height: max-content;
}

#detailContent .detailSidebar ul li a {
    color: var(--grey-50);
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    padding: 15px 20px;
    text-transform: uppercase;
    transition: .25s;
    width: 100%;
    background-color: var(--grey-10);
    border-left: 4px solid #1693D4;
    color: var(--grey-90);
} 
#detailContent .detailSidebar ul li a.active {
    border-left: 8px solid #1693D4;
    padding-left: 33px;
    color: var(--sub);

}
#detailContent .detailSidebar ul li a.osn { border-left: 4px solid var(--main); }
#detailContent .detailSidebar ul li a.uit { border-left: 4px solid var(--newsletter); }
#detailContent .detailSidebar ul li a.uit.active { border-left: 8px solid var(--newsletter); }

#detailContent .detailSidebar ul li .linkChildren {
    height: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    transition: .25s;
    -webkit-transform-origin: top;
    transform-origin: top;
}
#detailContent .detailSidebar ul li.active .linkChildren {
    height: -webkit-max-content;
    height: max-content;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}
#detailContent .detailSidebar ul li .linkChildren span {
    display: block;
    padding: 7px 10px 7px 35px;
    transition: .25s;
    font-size: 13px;
    cursor: pointer;
 }
#detailContent .detailSidebar ul li .linkChildren span.active {
    padding-left: 45px;
    border-left: 4px solid var(--sub);
    color: var(--sub);
}

 #detailContent .detailSidebar ul li .linkChildren span:hover {
    padding-left: 45px;
 }



#detailContent .detailSidebar  .buttonWrapper.S {
    height: 34px;
    width: 100%;
    margin-bottom: 65px;
}
.buttonText {
    margin-top: auto;
    padding: 0 20px;
}
.buttonText .icon {  margin-right: 10px; min-width: 20px;}
.buttonText span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
}

#detailContent .detailSidebar ul li a:hover {
    padding-left: 28px;
}


#detailContent .detailsMain {
    display: flex;
    width: calc(100% - 280px);
    align-items: center;
    flex-direction: column;
    height: calc(100% - 40px);
    margin-top: auto;
    padding: 20px 50px;
    margin-left: auto;
    position: relative;
    background-color: white;
}
#detailContent .detailsMain h2{
    min-height: 28px;
}

#detailContent .detailsMain .MagazineHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
#detailContent .detailsMain .MagazineHeader .magazineButton{
    background-color: #1693D4;
    position: relative;
    z-index:0; 
    border-radius: 3px;
    padding: 5px 10px;
    color: white;
    display: flex;
    align-items: center;
}
#detailContent .detailsMain .MagazineHeader .selectField { min-width: 150px !important}
#detailContent .detailsMain .MagazineHeader .magazineButton:after {
    content: "";
    position: absolute;
    background-color: #1793d4;
    top: 6px;
    left: 6px;
    right: -6px;
    bottom: -6px;
    opacity: .5;
    z-index:0;
    border-radius: 3px;
}



.cmp_collection_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    overflow: hidden;
    padding-top: 25px;
    width: 100%;
    max-width: 1411px;

    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    padding-top: 25px;
    width: 100%; */

}
/* .cmp_collection_wrapper::after {
    content: "";
    flex: auto;
} */

.cmp_wrapper_cn {
    height: -webkit-max-content;
    height: max-content;
    transition: .25s;
    padding-bottom: 50px;
    /* margin: 0 10px; */
    margin: 0 15px;
}
.mWrapper {
    cursor: pointer;
    display: block;
    transition: .25s;
}


.dd.magazine {
    border-radius: 5px;
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
    height: 200px;
    margin: 0 20px;
    max-height: 250px;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: .25s;
}
.dd.magazine:hover, .dd.magazine:hover+h5{
    -webkit-transform: translateY(-20px);
    transition: .25s;
    transform: translateY(-20px);
    color: var(--cn);
}


.dd.magazine img {
    display: block;
    height: 100%;
    margin: 0 auto;
    transition: .5s;
    width: auto;
}

.dd.magazine:hover img {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.dd.magazine h4 {
    background: #fff;
    font-size: 12px;
    left: 50%;
    letter-spacing: 1px;
    line-height: 18px;
    margin: 0;
    max-height: 121px;
    overflow: hidden;
    padding: 8px 5px;
    position: absolute;
    right: 0;
    text-align: center;
    text-overflow: ellipsis;
    top: 70%;
    -webkit-transform: scale(0) translate(-50%,-50%);
    transform: scale(0) translate(-50%,-50%);
    -webkit-transform-origin: left;
    transform-origin: left;
    transition: .25s;
    width: 100%;
  z-index:0;
    height: -webkit-max-content;
    height: max-content;
}

.dd.magazine:hover  h4 {
    color: var(--cn);
    top: 50%;
    -webkit-transform: scale(1) translate(-50%,-50%);
    transform: scale(1) translate(-50%,-50%);
}

.dd.magazine .ddOverlay {
    border-radius: 5px;
    bottom: 0;
    height: 100%;
    opacity: .7;
    padding: 25px;
    position: absolute;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    transition: .5s;
    width: 100%;
    }

.dd.magazine:hover .ddOverlay, .dd.magazine:hover .ddOverlay a {
    background-color: var(--cn);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.myWrapper h5 {
    align-items: center;
    border-radius: 0 0 5px 5px;
    bottom: -5px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    padding-top: 10px;
    text-align: center;
    transition: .25s;
    z-index:0;
    color: var(--grey-50);
}
.myWrapper h5 .icon {
    height: 20px;
    margin: 0 10px;
    width: 20px;
}
.noCmps {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.noCmps h3 {
    position: absolute;
    transform: translate(-285px, -60px);
    padding: 20px;
    color: var(--grey-90);
    border: 1px solid var(--cn);
    border-radius: 5px;
    background: var(--grey);
    box-shadow: 0 0 50px rgb(0 0 0 / 10%);
}
.noCmps.city h3 {
    transform: translate(-260px, -60px);
}
.noCmps h3::after {
    border-top: 5px solid var(--cn);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    bottom: -6px;
    width: 0;
}
.noCmps figure {
    height: 300px;
    position: relative;
}
.noCmps figure img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
}
#detailContent .detailsMain.uit { 
    margin-top: 40px;
    height: max-content;
    padding-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
} 
/* 
#detailContent .uit_item { 
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
    margin: 15px;
    background-color: white;
    transition: .2s;
    cursor: pointer;

}
#detailContent .uit_item:hover {
    background-color: var(--grey); 
}
#detailContent .uit_item figure{
    width: 100%; 
    position: relative;
    height: 150px;
    background-color: white;
    overflow: hidden;
}
#detailContent .uit_item figure img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
}
#detailContent .uit_item  h3 {
    padding: 25px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    height: 140px;
    color: var(--grey-100);
} */


.uit_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    padding: 20px;
    background-color: var(--grey-5);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: all 0.3s ease-in-out;
    width: 90%;
    height: 200px;
  }
  
  .uit_item:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  }
  
  .uit_item_image {
    width: 200px;
    min-width: 200px;
    height: 100%;
    min-height: 100%;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }
  
  .uit_item_image img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  
  .uit_item_details {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .uit_item_details h2 {
    font-size: 24px;
    margin: 0 0 10px 0;
    color: var(--grey-90);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  .uit_item_details span {
    margin-bottom: 5px;
    color: var(--cn);
}
  
  .uit_item_details p {
    font-size: 16px;
    margin: 0;
    color: var(--grey-70);
    height: 70px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .uit_item_details a {
    margin-top: auto;
    color: var(--cn);
    position: relative;
    width: max-content;
    font-weight: 900;

  }
  .uit_item_details a::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: var(--cn);
    left: 0;
    transition: 0.1s;
  }
  .uit_item_details a:hover::after {
    width: 100%;
  }


@media only screen and (max-width: 1170px) {
    .noCmps {
        flex-direction: column;
    }
    .noCmps h3 {
        position: relative;
        transform: none;
        order: 1;
    }
}

@media only screen and (max-width: 984px) {
    .cmp_collection_wrapper {
        justify-content: center;
    }
}
@media only screen and (max-width: 700px) {
    #detailContent .detailSidebar {
        position: absolute;
        transform: translate(-280px);
        transition: 0.25s;
    }
    #detailContent .detailSidebar.active {
        transform: unset;
      z-index:0;
    }
    #detailContent .detailsMain {
        width: 100%;
    }
    #detailContent .hamburgerIcon { display: block; z-index:0;}
    .icon.closeIcon {
        display: block;
    }

}

/* @media only screen and (max-width: 1790px) {
    .cmp_collection_wrapper {
        max-width: 1210px;
    }
}
@media only screen and (max-width: 1589px) {
    .cmp_collection_wrapper {
        max-width: 1008px;
    }
}
@media only screen and (max-width: 1387px) {
    .cmp_collection_wrapper {
        max-width: 807px;
    }
}
@media only screen and (max-width: 1186px) {
    .cmp_collection_wrapper {
        max-width: 606px;
    }
}
@media only screen and (max-width: 1186px) {
    .cmp_collection_wrapper {
        max-width: 606px;
    }
} */
.MyCity .MyCity_text p, .MyCity .MyCity_text .p {
    padding: 0 15px !important;
}

/* @media only screen and (max-width: 1790px) {
    .cmp_collection_wrapper {
        max-width: 1210px;
    }
}
@media only screen and (max-width: 1604px) {
    .cmp_collection_wrapper {
        max-width: 1008px;
    }
}
@media only screen and (max-width: 1402px) {
    .cmp_collection_wrapper {
        max-width: 807px;
    }
}
@media only screen and (max-width: 1201px) {
    .cmp_collection_wrapper {
        max-width: 606px;
    }
}
@media only screen and (max-width: 999px) {
    .cmp_collection_wrapper {
        justify-content: center;
    }
}

 */
