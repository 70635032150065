.shareButtons {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3e8bb45e;
}

.shareButtons .container {
    background-color: white;
    border: 1px solid var(--grey-10);
    border-radius: 5px;
    padding: 25px;
    z-index: 100;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.shareButtons .container .close {
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 5px;
    cursor: pointer;
    transition: .2s;
}

.shareButtons .container .close.back {
    left: 2px;
    right: unset;
}

.shareButtons .container .close:hover {
    transform: scale(1.1);
}

.shareButtons .container figure {
    height: 300px;
    display: flex;
    justify-content: center;
}

.shareButtons .container figure img {
    height: 100%;
}

.shareButtons .container .buttons {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
}

.shareButtons .container .shareButton {
    width: 40px;
    height: 40px;
    transition: .2s;
    position: relative;
    cursor: pointer;
}

.shareButtons .container h3 {
    font-size: 17px;
    text-align: center;
}

.shareButtons .container p {
    text-align: center;
    color: #1693D4
}

.shareButtons .container b {
    color: #1693D4;
}

.shareButtons .container .shareButton:hover {
    opacity: .8;
}

.shareButtons .container .buttons .icon {
    border: none;
    padding: 0px;
    font: inherit;
    cursor: pointer;
    background-color: var(--pn);
    color: white;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    padding: 5px;
}

.shareButtons .container .shareButton.facebook,
.shareButtons .container .shareButton.clipboard {
    top: -75px;
    background-color: #1693D4;
    /* Set the background color to purple */
}

.shareButtons .container .shareButton.email,
.shareButtons .container .shareButton.messenger {
    top: -50px;
}

.shareButtons .container .shareButton.twitter,
.shareButtons .container .shareButton.linkedin {
    top: -25px;
}

.shareButtons .container .labels {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.shareButtons .container .label {
    background-color: #1693D4;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

.shareButtons .container .label .icon {
    max-width: 15px;
    max-height: 15px;
    cursor: pointer;
    transition: .2s;
}

.shareButtons .container .label .icon:hover {
    transform: scale(1.1);
}

.shareButtons .container .inputFieldWrapper,
.shareButtons .container .form__field {
    max-width: unset;
}

.shareButtons .container .buttonWrapper {
    align-self: center;
    width: 20%;
}

.shareButtons .container .to_mails:hover+.infoText,
.shareButtons .container .infoText:hover {
    opacity: 1;
    pointer-events: inherit;
}

.shareButtons .container .infoText {
    display: flex;
    font-size: 11px;
    text-align: left;
    border: 1px solid var(--pn);
    border-radius: 5px;
    padding: 5px 10px;
    width: max-content;
    opacity: 0;
    transition: .5s;
    position: absolute;
    top: 210px;
    background: white;
    pointer-events: none;
    z-index: 9;
}

.shareButtons .container .infoText:before {
    border-bottom: 5px solid var(--pn);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    height: 0;
    left: 10%;
    position: absolute;
    top: -5px;
    width: 0;
}

.shareButtons .container .message {
    background-color: var(--grey-5);
    padding: 5px;
    border-radius: 5px;
}

.user_email,
.to_mails,
.infoText {
    align-self: center;
    margin-right: 45px;
}

.to-mails-input {
    display: flex;
    align-items: center;
    align-self: center;

    gap: 10px;
    /* Space between input and button */
}

.add-email-button {
    background-color: #1394d2;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.add-email-button:hover {
    background-color: #1173a8;
}

.add_emails_button {
    align-self: center;
}


.slider {
    height: auto;
    width: 35rem; /* Set to 100% if it's a responsive design */
    margin: 0 auto;
}

.slider img {
    width: 100%; /* Make sure images fill the container */
    height: auto;
    display: block;
}

.slider-container-share {
    justify-content: center;
    align-items: center;
}

.spinner {
    border: 2px solid rgba(0, 0, 255, 0.2); /* Light blue border */
    border-top: 4px solid blue; /* Blue border on top */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}